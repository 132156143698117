
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import Pagination from "@/components/base/paging/Pagination.vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import SendEmailForm from "@/views/email/SendEmailForm.vue";
import {useToast} from "vue-toast-notification";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "List",
  components: {Row, SendEmailForm, BaseModal, QuickAction, DateTimeFormat, Pagination},
  props: {
    objectId: {},
    objectType: {},
  },
  setup(props) {
    const objectFilter = ref({objectId: props.objectId, objectType: props.objectType})
    const page = computed(() => store.state.EmailModule.page);
    const detail = ref({show: false, data: {}});
    return {
      detail,
      objectFilter,
      page,
      ...LoadFilterObjects(Actions.LOAD_EMAILS, objectFilter.value, []),
      ...LoadPanel(),
    }
  },
  methods: {
    send() {
      const formSendEmailForm = this.$refs.formSendEmailForm as typeof SendEmailForm
      formSendEmailForm.onPreLoad();
      const modal = this.$refs.sendEmailRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSent() {
      useToast().success('Email Sent!!!', {position: "top-right"})
      const modal = this.$refs.sendEmailRef as typeof BaseModal;
      modal.hideBaseModal();
      this.paginationLoad()
    },
    onSelect(item) {
      this.detail.show= true;
      this.detail.data = item;
    }

  }
})
