
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import BaseForm from "@/components/base/form/BaseForm.vue";
import TemplateListSelect from "@/components/common/TemplateListSelect.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import Froala from "@/components/base/editor/Froala.vue";
import AttachmentFileButton from "@/components/base/upload/AttachmentFileButton.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import ClaimService from "@/core/services/ClaimService";
import {CaseContact, ClaimContact, CompanyContact, TridentContacts} from "@/core/composite/composite";
import {useToast} from "vue-toast-notification";
import CaseService from "@/core/services/CaseService";

export default defineComponent({
  name: "SendEmailForm",
  components: {BaseRadio, AttachmentFileButton, Froala, BaseSelect, FormErrorMessage, TemplateListSelect, BaseForm},
  props: {
    objectId: {},
    objectType: {},
  },
  emits: ['send'],
  setup() {
    const user = computed(() => store.getters.currentUser);
    const currentId = computed(() => store.state.TemplateModule.clientId);
    const emailRequest = ref({subject: '', body: '', type: 'PATIENT', emailTo: ''})
    const attachments = ref<any[]>([])
    const files = ref<any>([]);
    const submitting = ref(false);
    return {
      attachments,
      user,
      currentId,
      emailRequest,
      files,
      submitting,
      ...CompanyContact(''),
      ...CaseContact(''),
      ...ClaimContact(''),
      ...TridentContacts(),
    }
  },
  methods: {
    onPreLoad() {
      this.files = []
      this.attachments = [];
      this.emailRequest = {subject: '', body: '', type: 'PATIENT', emailTo: ''}
      if (this.objectType === 'CLAIM') {
        this.loadClaimContact(this.objectId)
      }
      if (this.objectType === 'CASE') {
        this.loadCaseContact(this.objectId)
      }
      this.loadCompanyContact(this.currentId);
      const select = this.$refs.templateEmailSelect as typeof TemplateListSelect
      select.clear();
    },
    onSelectedFile(file) {
      this.attachments.push(file)
    },

    onTemplateSelect(temp) {

      this.emailRequest.body = temp.body;
      this.emailRequest.subject = temp.subject;
      if (this.objectType === 'CLAIM') {
        this.submitting = true;
        ClaimService.emailFormat(this.objectId, this.emailRequest).then(res => {
          this.emailRequest.body = res.body
          this.emailRequest.subject = res.subject
        }).finally(() => {
          this.submitting = false;
        })
      }

      if (this.objectType === 'CASE') {
        this.submitting = true;
        CaseService.emailFormat(this.objectId, this.emailRequest).then(res => {
          this.emailRequest.body = res.body
          this.emailRequest.subject = res.subject
        }).finally(() => {
          this.submitting = false;
        })
      }

    },

    onRemoveFile(index) {
      this.files.splice(index, 1);
    },
    onContactSelect(email) {
      this.emailRequest.emailTo = email;
    },
    onSubmitSend() {
      this.submitting = true;
      if (this.emailRequest.body === '') {
        useToast().warning('Please input message body', {position: "top-right"})
        return
      }
      if (this.objectType === 'CLAIM') {
        this.submitting = true;
        ClaimService.sendAck(this.objectId, this.emailRequest, this.attachments).then(() => {
          this.submitting = false
          this.$emit('send')
        }).finally(() => {
          this.submitting = false;
        })
      }
      if (this.objectType === 'CASE') {
        this.submitting = true;
        CaseService.sendAck(this.objectId, this.emailRequest, this.attachments).then(() => {
          this.submitting = false
          this.$emit('send')
        }).finally(() => {
          this.submitting = false;
        })
      }
    },
    onRemove(index) {
      this.attachments.splice(index, 1);
    },
  }
})
